import { motion, useInView, useAnimation } from "framer-motion";
import { useEffect, useRef } from "react";

interface Props {
    children: JSX.Element;
    delay?: number;
    hidden?: any;
    visible?: any;
}

export const Reveal = ({ children, delay=0.25, hidden={ opacity: 0, x: -50, filter: 'blur(10px)' }, visible={ opacity: 1, x: 0, filter: 'blur(0px)' }}: Props) => {
    const ref = useRef(null);
    const isInView = useInView(ref, {
        margin: "0px 100px -100px 0px"
      });

    const mainControls = useAnimation();

    useEffect(() => {
        if (isInView) {
            mainControls.start("visible");
            console.log("yo")
        }
    }, [isInView]);

    return (
        <motion.div
            ref={ref}
            variants={{
                    hidden: hidden,
                    visible: visible
                }}
            initial="hidden"
            animate={mainControls}          // Controls the animation with code
            transition={{ duration: 0.75, delay: delay }}
        >
            {children}
        </motion.div>
    );
}