import Get_Connected from "../Get_Connected";
import { Helmet } from "react-helmet";
import { useHeading } from '../HeadingContext';
// import { useEffect, useRef, useState } from "react";

import { ReactTyped } from "react-typed";
import { Reveal } from "../Reveal";


type activeFunc = (page: string) => void;
type HomeProps = {
    setActivePageId: activeFunc;
}

const Home = ({ setActivePageId } : HomeProps) => {
    const { MinHeight } = useHeading()!;
    const home_1 = "/home_1.png";
    const question_1 = "/question_1.png";
    const answer_1 = "/answer_1.png";
    const question_2 = "/question_2.png";
    const answer_2 = "/answer_2.png";

    // const [isVisible, setIsVisible] = useState(false);
    // const elementRef = useRef(null);

    // useEffect(() => {
    //     const observer = new IntersectionObserver(entries => {
    //         entries.forEach(entry => {
    //             setIsVisible(entry.isIntersecting);
    //         });
    //     });

    //     if (elementRef.current) {
    //         observer.observe(elementRef.current);
    //     }

    //     return () => {
    //     if (elementRef.current) {
    //         observer.unobserve(elementRef.current);
    //     }
    //     };
    // }, []);

    return (  
        <div className="home bg-primary" style={{ minHeight: MinHeight }}>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Early Learning Kingdom - Home</title>
                <link rel="canonical" href="http://earlylearningkingdom.com/home" />
                <meta name="description" content="Home Page" />
            </Helmet>
            {/* <div className="banner font-bold text-center text-5xl text-secondary
            bg-gradient-to-t from-tertiary to-primary py-4 font-montserrat z-20">
                <h1>ELK</h1>
            </div> */}
            <div className="landing" style={{ minHeight: MinHeight }}>
                <div className="flex px-[12vw]">
                    <div className="flex flex-col sm:pt-[12vh] xl:w-1/2 items-center xl:items-start">
                        <div className="text-center xl:text-left">
                            <Reveal>
                                <h2 className="title">Unlock <span className="text-color_4">Automation Testing</span> and <span className="text-color_4">CI/CD Expertise</span></h2>
                            </Reveal>
                            {/* <h4 ref={elementRef} className="big-size">
                                {isVisible && 
                                 <ReactTyped strings={["Get connected with our experts and level up your enterprise with automated quality assurance and improved asset data tracking"]} typeSpeed={8}/>}
                            </h4> */}
                            <div className="medium-size">
                                <ReactTyped strings={["Get connected with our experts and level up your enterprise with automated quality assurance and improved asset data tracking"]} typeSpeed={8}/>
                            </div>
                        </div>
                        <div className="flex relative py-[8vh]">
                            <Reveal delay={2.75}>
                                <Get_Connected setActivePageId={(pageId)=>setActivePageId(pageId)}/>
                            </Reveal>
                        </div>
                    </div>
                    <div className="hidden xl:flex pl-[6vw] xl:w-1/2 relative justify-center items-center text-center xl:text-left">
                        <Reveal delay={2.25}>
                            <img className="object-contain h-96 lg:w-144 unselectable" src={home_1} alt=""/>
                        </Reveal>
                    </div>
                </div>
            </div>
            <div className="speeches" style={{ minHeight: MinHeight }}>
                <div className="flex flex-col px-[8vw] pt-[8vh] pb-36">
                    <Reveal hidden={{ opacity: 0, x: -50, y: 100, rotate: 5, filter: 'blur(10px)' }} visible={{ opacity: 1, x: 0, y: 0, rotate: 0, filter: 'blur(0px)' }}>
                        <div className="flex justify-start h-40 xl:h-60">
                            <div className="flex justify-center items-center">
                                <img className="object-contain unselectable h-20 mobile:h-24 sm:h-36 lg:h-44 xl:h-52" src={question_1} alt=""/>
                                <div className="question absolute">Regression risks?<br />Performance concerns?</div>
                            </div>
                        </div>
                    </Reveal>
                    <Reveal delay={0.35} hidden={{ opacity: 0, x: 50, y: 100, rotate: -5, filter: 'blur(10px)' }} visible={{ opacity: 1, x: 0, y: 0, rotate: 0, filter: 'blur(0px)' }}>
                        <div className="flex justify-end h-28">
                            <div className="flex justify-center items-center">
                                <img className="object-contain unselectable h-16 mobile:h-20 sm:h-28 lg:h-36 xl:h-44" src={answer_1} alt=""/>
                                <div className="answer absolute">We got you covered!</div>
                            </div>
                        </div>
                    </Reveal>
                    <Reveal delay={0.45} hidden={{ opacity: 0, x: -50, y: 100, rotate: 5, filter: 'blur(10px)' }} visible={{ opacity: 1, x: 0, y: 0, rotate: 0, filter: 'blur(0px)' }}>
                        <div className="flex justify-start h-40 xl:h-60">
                            <div className="flex justify-center items-center">
                                <img className="object-contain unselectable h-16 mobile:h-20 sm:h-28 lg:h-36 xl:h-44" src={question_2} alt=""/>
                                <div className="question absolute">Cross-platform?</div>
                            </div>
                        </div>
                    </Reveal>
                    <Reveal delay={0.55} hidden={{ opacity: 0, x: 50, y: 100, rotate: -5, filter: 'blur(10px)' }} visible={{ opacity: 1, x: 0, y: 0, rotate: 0, filter: 'blur(0px)' }}>
                        <div className="flex justify-end h-28">
                            <div className="flex justify-center items-center">
                                <img className="object-contain unselectable h-16 mobile:h-20 sm:h-28 lg:h-36 xl:h-44" src={answer_2} alt=""/>
                                <div className="answer absolute">No problem!</div>
                            </div>
                        </div>
                    </Reveal>
                </div>
            </div>
        </div>
    );
}
 
export default Home;