import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";
import { useHeading } from '../HeadingContext';
import { motion } from "framer-motion";


import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Reveal } from "../Reveal";

// function Arrow(props: any) {
//     const { className, style, onClick } = props;
//     return (
//         <div
//         className={className}
//         style={{ ...style, display: "block" }}
//         onClick={onClick}
//         />
//     );
// }

const variants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };

const Services = () => {
    const { MinHeight } = useHeading()!;
    const web_app_automation_test = "/web-coding.png";
    const mobile_app_automation_test = "/mobile development.png";
    const ci_cd = "/devops.png";

    var settings = {
        dots: true,
        infinite: true,
        speed: 750,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
        // nextArrow: <Arrow className="slick-next" />,
        // prevArrow: <Arrow className="slick-prev" />,
      };

    const [demo, setDemo] = useState("");
    const [videoKeys, setVideoKeys] = useState({ webAppAutomation: false, CICD: false, mobileAppAutomation: false });
    const [playDemo, setPlayDemo] = useState(false);
    const [animationKey, setAnimationKey] = useState(0); 

    const playDemoVideo = (demo : string) => {
        setDemo(demo);
        setPlayDemo(true);
    }

    useEffect(() => {
        if (demo) { // Check if demo has content to ensure animation only plays when demo is updated
            console.log("Loading...");
            if (demo === "webAppAutomation") {
                setVideoKeys({ webAppAutomation: true, CICD: false, mobileAppAutomation: false });
            }
            if (demo === "CICD") {
                setVideoKeys({ webAppAutomation: false, CICD: true, mobileAppAutomation: false });
            }
            if (demo === "mobileAppAutomation") {
                setVideoKeys({ webAppAutomation: false, CICD: false, mobileAppAutomation: true });
            }
            
            setAnimationKey(prevKey => prevKey + 1);
        }
    }, [demo]); // Depend on the demo state

    useEffect(() => {
        console.log("keys", videoKeys);
    }, [videoKeys]);

    return (  
        <div className="services bg-white" style={{ minHeight: MinHeight }}>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Early Learning Kingdom - Services</title>
                <link rel="canonical" href="http://earlylearningkingdom.com/services" />
                <meta name="description" content="Services Page" />
            </Helmet>
            <div className="relative shadow-top">
                <Reveal>
                    <h2 className="pop-size font-bold text-secondary text-left px-[12vw] pt-[15vh] pb-[12vh] text-center">WHAT WE DO</h2>
                </Reveal>
                <div className="lg:hidden pb-[16vh]">
                    <Reveal>
                        <Slider {...settings}>
                            <div className="text-center pb-4">
                                <div className="bg-primary inline-block shadow-lg rounded-lg py-[8vh] w-[60vw] md:w-[40vw]">
                                    <img className="object-contain h-8 xs:h-12 md:h-20 rounded-b mx-auto" src={web_app_automation_test} alt="" />
                                    <div className="flex mt-5 items-center h-8 xs:h-12 md:h-20">
                                        <h3 className="subtitle mx-auto">Web App <br/> Automation</h3>
                                    </div>
                                    <button className="demo_video demo-button" onClick={() => {playDemoVideo("webAppAutomation")}}>
                                        DEMO
                                    </button>
                                </div>
                            </div>
                            <div className="text-center pb-4">
                                <div className="bg-primary inline-block shadow-lg rounded-lg py-[8vh] w-[60vw] md:w-[40vw]">
                                    <img className="object-contain h-8 xs:h-12 md:h-20 rounded-b mx-auto" src={ci_cd} alt="" />
                                    <div className="flex mt-5 items-center h-8 xs:h-12 md:h-20">
                                        <h3 className="subtitle mx-auto text-2xl">CI/CD</h3>
                                    </div>
                                    <button className="demo_video demo-button" onClick={() => {playDemoVideo("CICD")}}>
                                        DEMO
                                    </button>
                                </div>
                            </div>
                            <div className="text-center pb-4">
                                <div className="bg-primary inline-block shadow-lg rounded-lg py-[8vh] w-[60vw] md:w-[40vw]">
                                    <img className="object-contain h-8 xs:h-12 md:h-20 rounded-b mx-auto" src={mobile_app_automation_test} alt="" />
                                    <div className="flex mt-5 items-center h-8 xs:h-12 md:h-20">
                                        <h3 className="subtitle mx-auto">Mobile App<br/> Automation</h3>
                                    </div>
                                    <button className="demo_video demo-button" onClick={() => {playDemoVideo("mobileAppAutomation")}}>
                                        DEMO
                                    </button>
                                </div>
                            </div>
                        </Slider>
                    </Reveal>
                </div>
                <div className="hidden lg:flex flex-col lg:flex-row px-[8vw] pb-[16vh]">
                    <div className="flex-1 text-center">
                        <Reveal delay={0.50}>
                            <div className="bg-primary inline-block shadow-lg rounded-lg py-[8vh] w-[25vw]">
                                <img className="object-contain h-8 xs:h-12 md:h-20 rounded-b mx-auto" src={web_app_automation_test} alt="" />
                                <div className="flex mt-5 items-center h-8 xs:h-12 md:h-20">
                                    <h3 className="subtitle mx-auto">Web App <br/> Automation</h3>
                                </div>
                                <button className="demo_video demo-button" onClick={() => {playDemoVideo("webAppAutomation")}}>
                                    DEMO
                                </button>
                            </div>
                        </Reveal>
                    </div>
                    <div className="flex-1 text-center">
                        <Reveal delay={0.8}>
                            <div className="bg-primary inline-block shadow-lg rounded-lg py-[8vh] w-[25vw]">
                                <img className="object-contain h-8 xs:h-12 md:h-20 rounded-b mx-auto" src={ci_cd} alt="" />
                                <div className="flex mt-5 items-center h-8 xs:h-12 md:h-20">
                                    <h3 className="subtitle mx-auto text-2xl">CI/CD</h3>
                                </div>
                                <button className="demo_video demo-button" onClick={() => {playDemoVideo("CICD")}}>
                                    DEMO
                                </button>
                            </div>
                        </Reveal>
                    </div>
                    <div className="flex-1 text-center">
                        <Reveal delay={1.1}>
                            <div className="bg-primary inline-block shadow-lg rounded-lg py-[8vh] w-[25vw]">
                                <img className="object-contain h-8 xs:h-12 md:h-20 rounded-b mx-auto" src={mobile_app_automation_test} alt="" />
                                <div className="flex mt-5 items-center h-8 xs:h-12 md:h-20">
                                    <h3 className="subtitle mx-auto">Mobile App <br/> Automation</h3>
                                </div>
                                <button className="demo_video demo-button" onClick={() => {playDemoVideo("mobileAppAutomation")}}>
                                    DEMO
                                </button>
                            </div>
                        </Reveal>
                    </div>
                </div>
            </div>
            {playDemo &&
            <div className="lg:hidden pb-[16vh] px-[8vw] py-[10vh]">
                <div className="title">Check it out...</div>
                {/* <div className={`${animate ? 'fade-in' : ''}`}> */}
                <div className="demo">
                    <motion.div
                        key={animationKey} // Use key to force re-render
                        initial="hidden"
                        animate="visible"
                        variants={variants}
                        transition={{ duration: 0.5 }}
                    >
                        <div className={`${!videoKeys.webAppAutomation && "hidden"}`}>
                            <div className="subtitle">Technologies Used: <span className="font-normal">Python + Pytest + Selenium Webdriver</span></div>
                            <div className="video-player flex justify-center">
                                <div className="w-[75vw]" style={{aspectRatio: "1440/836"}}>
                                    <iframe className="embeddedObject shadow-2xl resizable rounded-lg w-full h-full" name="embedded_content" scrolling="no"
                                        style={{"overflow":"hidden;"}} src="https://www.screencast.com/users/Mingli720/folders/Snagit/media/57f88633-0c1d-4580-95a9-9d2e67f22ac6/embed" allowFullScreen>
                                    </iframe>
                                </div>
                            </div>
                        </div>
                        <div className={`${!videoKeys.CICD && "hidden"}`}>
                            <div className="subtitle">Description: <span className="font-normal">Automation test running on the Selenium-Grid within Jenkins</span></div>
                            <div className="video-player flex justify-center ">
                                <div className="w-[75vw]" style={{aspectRatio: "1440/836"}}>
                                    <iframe className="embeddedObject shadow-2xl resizable rounded-lg" name="embedded_content" scrolling="no"
                                        style={{"overflow":"hidden;"}} src="https://www.screencast.com/users/Mingli720/folders/Snagit/media/468a485f-8ab2-46c5-8fc2-c5592044d4be/embed" height="836" width="1440" allowFullScreen>
                                    </iframe>
                                </div>
                            </div>
                        </div>
                        <div className={`${!videoKeys.mobileAppAutomation && "hidden"}`}>
                            <div className="subtitle">Technologies Used: <span className="font-normal">Python + Pytest + Selenium Webdriver + Appium + Android Emulator</span></div>
                            <div className="video-player flex justify-center ">
                                <div className="w-[75vw]" style={{aspectRatio: "1440/836"}}>
                                    <iframe className="embeddedObject shadow-2xl resizable rounded-lg" name="embedded_content" scrolling="no"
                                        style={{"overflow":"hidden;"}} src="https://www.screencast.com/users/Mingli720/folders/Snagit/media/88951aa3-9196-4cbf-8939-88a5a3406cc1/embed" height="836" width="1440" allowFullScreen>
                                    </iframe>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </div>
            </div>}
        </div>
    );
}
 
export default Services;