import { Page } from "./pages/Page";
import Heading from "./Heading";
import Home from "./pages/Home";
import Services from "./pages/Services";
import Clients from "./pages/Clients";
import Contact from "./pages/Contact";
import { useState, useEffect, ReactElement } from "react";
import { useScroll, useMotionValueEvent } from "framer-motion";
import { useHeading } from "./HeadingContext";


export type PageInfo = {
    heading: string,
    navTitle: string,
    child: ReactElement<any, any>,
    id: string
}

function getActivePage(pct: number, pages: PageInfo[], headingHeight: number){
    const totalScrollable = document.documentElement.getBoundingClientRect().height - document.documentElement.clientHeight + headingHeight;
    var curPct = 0
    for(var i of pages){
        var pctOfTotal = (document.getElementById(i.id)?.getBoundingClientRect().height ?? 0) / totalScrollable;
        if(pct <= curPct + pctOfTotal){
            return i.id
        }
        curPct += pctOfTotal;
    }

    return pages[pages.length - 1].id;
}

function App() {
    const { HeadingHeight } = useHeading()!;
    const { scrollYProgress } = useScroll();

    const [activePageId, setActivePageId] = useState<string>();

    const pages: PageInfo[] = [
        {heading: "Home", navTitle: "Home", id:"home", child: <Home setActivePageId={(pageId)=>setActivePageId(pageId)}/>},
        {heading: "Services", navTitle: "Services", id:"services", child: <Services/>},
        {heading: "Clients", navTitle: "Clients", id:"clients", child: <Clients/>},
        {heading: "Contact", navTitle: "Contact", id:"contact", child: <Contact/>}
      ];

    useEffect(()=>{
        var h = document.documentElement, 
        b = document.body
        
        var percent = (h.scrollTop||b.scrollTop) / ((h.scrollHeight||b.scrollHeight) - h.clientHeight);
        percent = Math.min(0.99, percent);
        percent = Math.max(0, percent);
        setActivePageId(getActivePage(percent, pages, HeadingHeight))
    },[])

    useMotionValueEvent(scrollYProgress, "change", (latest) => {
        const active = getActivePage(latest, pages, HeadingHeight)
        setActivePageId(active)
    })


    return (
        <div>
            <Heading allPages={pages} activePageId={activePageId!} setActivePageId={(pageId)=>setActivePageId(pageId)}/>
            <div className="w-full relative" style={{ top: HeadingHeight }}>
                {pages.map((page) => <Page key={page.navTitle} info={page}/>)}
            </div>
        </div>
    );
}

export default App;
